<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The National Fire Protection Association (NFPA) diamond has a [A] section for flammability.
        If the number [B] appears in this section, the chemical is extremely flammable. If the
        number [C] appears in this section, the chemical is a combustible, it will catch fire when
        heated. The Global Harmonized System (GHS) symbol is a picture of a [D]. If the hazard
        category is [E], the chemical is extremely flammable. If the hazard category is [F], the
        chemical is combustible.
      </p>

      <p class="mb-2">
        [A]:
        <v-select
          v-model="inputs.input1"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="items1"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        [B]:
        <v-select
          v-model="inputs.input2"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="items2"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        [C]:
        <v-select
          v-model="inputs.input3"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="items2"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        [D]:
        <v-select
          v-model="inputs.input4"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="items3"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        [E]:
        <v-select
          v-model="inputs.input5"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="items4"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        [F]:
        <v-select
          v-model="inputs.input6"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="items4"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LDQ1S2Q8',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      items1: [
        {text: 'red', value: 'red'},
        {text: 'blue', value: 'blue'},
        {text: 'yellow', value: 'yellow'},
        {text: 'white', value: 'white'},
      ],
      items2: [
        {text: '4', value: 'four'},
        {text: '3', value: 'three'},
        {text: '2', value: 'two'},
        {text: '1', value: 'one'},
        {text: '0', value: 'zero'},
      ],
      items3: [
        {text: 'fire', value: 'fire'},
        {text: 'fire with an O in the middle', value: 'middle'},
        {text: 'an explosion', value: 'explosion'},
        {text: 'an exclamation point', value: 'exclamation'},
        {text: 'a skull and crossbones', value: 'crossbones'},
      ],
      items4: [
        {text: 'HC1', value: 'hc1'},
        {text: 'HC2', value: 'hc2'},
        {text: 'HC3', value: 'hc3'},
        {text: 'HC4', value: 'hc4'},
      ],
    };
  },
};
</script>
<style scoped></style>
