var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" The National Fire Protection Association (NFPA) diamond has a [A] section for flammability. If the number [B] appears in this section, the chemical is extremely flammable. If the number [C] appears in this section, the chemical is a combustible, it will catch fire when heated. The Global Harmonized System (GHS) symbol is a picture of a [D]. If the hazard category is [E], the chemical is extremely flammable. If the hazard category is [F], the chemical is combustible. ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" [A]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [B]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [C]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [D]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.items3,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [E]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.items4,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [F]: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.items4,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }